import React from 'react'
import { Footer, Navbar } from 'layouts'
import SEO from '../components/SEO'

import styled from 'styled-components'
import '../styles/styles.css'
import CookieNotice from './CookieNotice'

const ChildWrapper = styled.div`
  padding-top: 2rem;
`

const Layout = ({ children }) => (
  <>
    <SEO />
    <Navbar />
    <ChildWrapper id="childWrapper">{children}</ChildWrapper>
    <Footer />
  </>
)

export default Layout
