import React from 'react'
import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react'
import styled from 'styled-components'
import { Link } from 'gatsby'

const Container = styled.div`
  height: 730px;
  width: 100%;
  z-index: 100;
  margin-top: 23px;
  margin-bottom: -4rem;
`

const StyledLink = styled(Link)`
  color: #333;
  background-color: #f2f6ff;
  border-color: #6997ef;
  border-radius: 4px;
  padding: 8px 12px;
  margin: 5px 0;
  font-size: 15px;
  text-align: center;
  cursor: pointer;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover {
    color: white;
    background-color: #dd3030;
  }
`

class MapContainer extends React.Component {
  constructor() {
    super()

    this.state = {
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
    }
  }

  onMarkerClick = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
    })

  onMapClicked = props => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
      })
    }
  }

  render() {
    const points = this.props.markers.map(marker => ({
      lat: marker.lat,
      lng: marker.lng,
    }))

    let bounds = new this.props.google.maps.LatLngBounds()
    for (let i = 0; i < points.length; i++) {
      bounds.extend(points[i])
    }
    return (
      <Container className="map-container">
        <Map
          google={this.props.google}
          zoom={13}
          style={{ height: '730px', width: '100%' }}
          initialCenter={{
            lat: this.props.markers[0].lat,
            lng: this.props.markers[0].lng,
          }}
          // bounds={bounds}
        >
          {this.props.markers.map(({ title, description, link, lat, lng }) => {
            return (
              <Marker
                onClick={this.onMarkerClick}
                title={title}
                key={title}
                description={description}
                link={link}
                position={{ lat: lat, lng: lng }}
              />
            )
          })}

          <InfoWindow
            marker={this.state.activeMarker}
            visible={this.state.showingInfoWindow}
            onClose={this.onMapClicked}
          >
            <div
              style={{
                width: '250px',
                maxHeight: '150px',
                height: 'auto',
                marginBottom: '10px',
              }}
            >
              <h1 style={{ margin: '5px 0' }}>
                {this.state.selectedPlace.title}
              </h1>
              <p style={{ marginBottom: '20px' }}>
                {this.state.selectedPlace.description}
              </p>
              <StyledLink to={`${this.state.selectedPlace.link}`}>
                Visit page
              </StyledLink>
            </div>
          </InfoWindow>
        </Map>
      </Container>
    )
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyDeSdA2YkTm5c_p6Qw4fOgD9cxcOpzehjI',
})(MapContainer)
