export const defaultSideBarLinks = [
  { title: 'Central London', link: '/central-london-attractions' },
  { title: 'Map of Central London', link: '/central-london-map' },
  { title: 'Canary Wharf', link: '/visit-canary-wharf-attractions' },
  { title: 'Map of Canary Wharf', link: '/canary-wharf-map' },
  {
    title: 'Bethnal Green/Whitechapel',
    link: '/visit-bethnal-green-whitechapel-attractions',
  },
]

export const suggestionLink = {
  title: '2-minute quiz',
  link: '/quiz',
}
