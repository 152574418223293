import { Link } from 'gatsby'
import React, { useEffect } from 'react'
import { useState } from 'react'
import styled from 'styled-components'
const { default: CookieConsent } = require('react-cookie-consent')

const padding = '15px'
const analytic_cookie = 'gatsby-gdpr-google-analytics'

const Wrapper = styled.div`
  & .cc {
    bottom: ${padding} !important;
    right: ${padding} !important;
    left: auto !important;
    /* left: auto !important; */
    max-width: 600px !important;
    width: calc(100% - ${padding} * 2) !important;
    border-radius: 10px;
    background-color: #161b2e !important;
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.3);
    align-items: center !important;

    @media (max-width: 400px) {
      flex-direction: column;

      & .cc-content {
        margin-bottom: 0px !important;
      }
    }

    & .cc-content {
      flex: 1 !important;
      /* max-width: 370px; */
      margin-block: 12px !important;
      font-size: 0.9rem;
      height: 100%;
      line-height: 1.3rem;

      a {
        text-decoration: underline;
        color: white;
        &:hover {
          opacity: 0.7;
        }
      }
    }

    & .cc-button {
      border-radius: 5px !important;
      font-size: 0.9rem;
      padding: 6px 12px !important;
      margin-left: 0px !important;
      color: white !important;

      &#cc-accept-button {
        background-color: #00a900 !important;
      }
    }
  }
`

const CookieNotice = () => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    initCountry()
      .then(result => {
        //result => {"countryCode":"JP","IP":"124.145.154.33"}
        if (GDPR_COUNTRY_CODES.includes(result?.countryCode || '')) {
          setShow(true)
        } else {
          const date = new Date()
          date.setFullYear(date.getFullYear() + 1)
          document.cookie = `${analytic_cookie}=true; expires=${date.toUTCString()}; path=/`
        }
      })
      .catch(e => console.log(e))
  }, [])

  if (!show) return null

  return (
    <Wrapper>
      <CookieConsent
        containerClasses="cc"
        contentClasses="cc-content"
        buttonClasses="cc-button"
        buttonId="cc-accept-button"
        buttonText="Accept"
        enableDeclineButton
        declineButtonClasses="cc-button"
        declineButtonId="cc-decline-button"
        declineButtonText="Decline"
        cookieName={analytic_cookie}
      >
        We use cookies and other tracking technologies to improve your browsing
        experience on our website. <Link to="/legal">Learn more</Link>
      </CookieConsent>
    </Wrapper>
  )
}

export default CookieNotice

//regular expressions to extract IP and country values
const countryCodeExpression = /loc=([\w]{2})/
const userIPExpression = /ip=([\w\.]+)/

const GDPR_COUNTRY_CODES = [
  'AT',
  'BE',
  'BG',
  'CY',
  'CH',
  'CZ',
  'DE',
  'DK',
  'EE',
  'ES',
  'FI',
  'FR',
  'GB',
  'GR',
  'HR',
  'HU',
  'IE',
  'IS',
  'IT',
  'LI',
  'LT',
  'LU',
  'LV',
  'MT',
  'NL',
  'NO',
  'PL',
  'PT',
  'RO',
  'SE',
  'SI',
  'SK',
]

//automatic country determination.
const initCountry = () => {
  return new Promise((resolve, reject) => {
    var xhr = new XMLHttpRequest()
    xhr.timeout = 3000
    xhr.onreadystatechange = function () {
      if (this.readyState == 4) {
        let countryCode
        let ip
        if (this.status == 200) {
          countryCode = countryCodeExpression.exec(this.responseText)
          ip = userIPExpression.exec(this.responseText)
          if (
            countryCode === null ||
            countryCode[1] === '' ||
            ip === null ||
            ip[1] === ''
          ) {
            reject('IP/Country code detection failed')
          }
          let result = {
            countryCode: countryCode[1],
            IP: ip[1],
          }
          resolve(result)
        } else {
          reject(xhr.status)
        }
      }
    }
    xhr.ontimeout = function () {
      reject('timeout')
    }
    xhr.open('GET', 'https://www.cloudflare.com/cdn-cgi/trace', true)
    xhr.send()
  })
}
