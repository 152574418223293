module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: 'London-Travel.co.uk', // Navigation and Site Title
  titleAlt: 'London-Travel', // Title for JSONLD
  description: 'Welcome to London Travel!',
  url: 'https://london-travel.co.uk', // Domain of your site. No trailing slash!
  siteUrl: 'https://london-travel.co.uk/', // url + pathPrefix
  siteLanguage: 'en', // Language Tag on <html> element
  logo: '/logo/logo.png', // Used for SEO
  banner: '/logo/banner.jpg', // Banner for SEO
  favicon: 'static/logo/favicon.png', // Used for manifest favicon generation
  shortName: 'LondonTravel', // shortname for manifest. MUST be shorter than 12 characters
  author: 'Tim', // Author for schemaORGJSONLD
  themeColor: '#161C2E',
  backgroundColor: '#161b2e',
  twitter: '', // Twitter Username
  adsenseClientId: 'ca-pub-5830871296615852', //eg: ca-pub-7292810486004926
  adsenseSlotId: '8912228951',
  searchConsoleId: '',
}
