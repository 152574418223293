import React from 'react'
import { Link } from 'gatsby'

const SideMenu = ({ markers, title }) => {
  return (
    <div
      className="col-sm-push-9 col-sm-3 map"
      id="sidebar"
      style={{
        position: 'absolute',
        width: '230px',
        right: 0,
        top: '4rem',
        zIndex: '20',
      }}
    >
      <div className="list-group">
        <h4 className="list-group-item active list-group-item-heading">
          {title}
        </h4>
        {markers.length > 7
          ? markers.slice(0, 7).map(marker => (
              <Link
                to={marker.link}
                className="list-group-item"
                key={marker.title}
                style={{ zIndex: 1, borderRadius: 0 }}
              >
                <h5 className="list-group-item-heading">{marker.title}</h5>
              </Link>
            ))
          : markers.map(marker => (
              <Link
                to={marker.link}
                className="list-group-item"
                key={marker.title}
              >
                <h5 className="list-group-item-heading">{marker.title}</h5>
              </Link>
            ))}
        <Link to="/gallery" className="list-group-item active">
          <h4 className="list-group-item-heading">Our Impressionist Gallery</h4>
        </Link>
        <Link
          to="/central-london-attractions"
          className="list-group-item active"
        >
          <h4 className="list-group-item-heading">Central London</h4>
        </Link>
      </div>
    </div>
  )
}

export default SideMenu
