/*

All grid code is placed in a 'supports' rule (feature query) at the bottom of the CSS (Line 310). 

The 'supports' rule will only run if your browser supports CSS grid.

Flexbox and floats are used as a fallback so that browsers which don't support grid will still recieve a similar layout.

*/

const galleryStyles = `
  /* Base Styles */
  .gallery-wrapper {
    font-size: 1px;
    box-sizing: border-box;
  }

  .gallery-container {
    max-width: 75.5rem;
    margin: 0 auto;
    padding: 0 2rem;
  }

  /* Gallery Section */

  .gallery {
    display: flex;
    flex-wrap: wrap;
    margin: -1rem -1rem;
    padding-bottom: 3rem;
    justify-content: center;
    align-items: center;
    justify-items: center;
  }

  .gallery-item {
    position: relative;
    height: 99%;
    flex: 1 0 22rem;
    margin: 0 1rem;
    color: #fff;
    cursor: pointer;
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2);
    transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    border-radius: 0.4rem;
  }

  .gallery-item .gatsby-image-wrapper,
  .gallery-image {
    transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    border-radius: 0.4rem;
  }

  .gallery-item:focus {
    outline: none;
  }

  .gallery-item-likes {
    margin-right: 2.2rem;
  }

  .gallery-item:hover,
  .gallery-item:focus{
    transform: scale(1.03)
  }

  .gallery-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @supports (display: grid) {
    .gallery {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(17rem, 1fr));
      grid-gap: 2rem;
    }

    .gallery-item,
    .gallery {
      width: auto;
      margin: 0;
    }
  }
`

export default galleryStyles
