import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import galleryStyles from '../../styles/galleryStyles'

export default function Instagram() {
  const [photos, setPhotos] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  const fields = ['media_url', 'id', 'caption', 'permalink'].join()
  const accessToken = process.env.GATSBY_INSTAGRAM_TOKEN
  const url = `https://graph.instagram.com/me/media?fields=${fields}&access_token=${accessToken}`

  useEffect(() => {
    fetch(url)
      .then(data => data.json())
      .then(res => {
        const dupl = [...res.data]
        dupl.pop()
        setPhotos(dupl)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
        setError(true)
      })
  }, [])

  return (
    <div className="gallery-wrapper" css={galleryStyles}>
      <div className="gallery-container" style={{ paddingTop: '80px' }}>
        {loading === true ? (
          <div style={{ margin: '0 auto', maxWidth: '200px' }}>
            <h1>Loading ...</h1>
          </div>
        ) : (
          <div className="gallery">
            {error && (
              <div
                style={{
                  margin: '0 auto',
                  maxWidth: '500px',
                  textAlign: 'center',
                }}
              >
                <h3>Please try again a little while later.</h3>
                <h1>
                  We are very sorry, but it seems like we couldn't find the
                  images...
                </h1>
              </div>
            )}
            {photos &&
              photos.map(({ media_url, permalink, id, caption }) => (
                <a
                  href={permalink}
                  target="_blank"
                  className="gallery-item"
                  rel="noopener noreferrer"
                  key={id}
                >
                  <img
                    src={media_url}
                    className="gallery-image"
                    alt={caption.substring(0, 40)}
                  />
                </a>
              ))}
          </div>
        )}
      </div>
    </div>
  )
}
