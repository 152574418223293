export const finalScore = {
  eight: 'Congratulations, you know your Kennington from your Kensington!',
  six: 'Good work. But check out our Central London page to get top marks!',
  four:
    "Not bad, but there's room for improvement!  Start with our Central London page.",
  other: 'You need to do some serious research!',
}

export const questions = [
  {
    question: 'How many times has London hosted the Olympics?',
    choice1: 1,
    choice2: 2,
    choice3: 3,
    choice4: 4,
    answer: 3,
  },
   {
    question: 'How many world heritage sites does London have?',
    choice1: 1,
    choice2: 2,
    choice3: 3,
    choice4: 4,
    answer: 3,
  },
    {
    question: 'When did London first host the Olympics?',
    choice1: 1908,
    choice2: 1948,
    choice3: 1966,
    choice4: 2012,
    answer: 1,
  },
  {
    question: 'When was Charles I exectuted in Whitehall, London?',
    choice1: 1610,
    choice2: 1649,
    choice3: 1699,
    choice4: 1719,
    answer: 2,
  },
  {
    question: 'What is the tallest building in London?',
    choice1: 'The Shard',
    choice2: 'Canary Wharf',
    choice3: 'Tower 42',
    choice4: 'BT Tower',
    answer: 1,
  },
  {
    question: 'When were the Houses of Parliament built?',
    choice1: 1790,
    choice2: 1801,
    choice3: 1821,
    choice4: 1860,
    answer: 4,
  },
   {
    question: 'When did the Great Fire of London take place?',
    choice1: 1555,
    choice2: 1666,
    choice3: 1777,
    choice4: 1888,
    answer: 2,
  },
   {
    question: 'The Great Exhibition was hosted in Crystal Palace, London, in which year?',
    choice1: 1811,
    choice2: 1834,
    choice3: 1851,
    choice4: 1888,
    answer: 3,
  },
  {
    question: 'Which of these football teams is not based in London?',
    choice1: 'Tottenham Hotspurs',
    choice2: 'Arsenal',
    choice3: 'Aston Villa',
    choice4: 'West Ham',
    answer: 3,
  },
  {
    question: 'Which cricket team holds its home matches at Lords cricket ground?',
    choice1: 'Surrey',
    choice2: 'Sussex',
    choice3: 'Kent',
    choice4: 'Middlesex',
    answer: 4,
  },
    {
    question: 'Which cricket team holds its home matches at The Oval?',
    choice1: 'Surrey',
    choice2: 'Sussex',
    choice3: 'Kent',
    choice4: 'Middlesex',
    answer: 1,
  },
  {
    question: 'How many London boroughs are there (excluding the City of London)?',
    choice1: 32,
    choice2: 35,
    choice3: 39,
    choice4: 41,
    answer: 1,
  },
  {
    question: 'What was London called after the Roman Invasion of 42 AD',
    choice1: 'Rome Minor',
    choice2: 'Londinium',
    choice3: 'Capita Brittanica',
    choice4: 'London Town',
    answer: 2,
  },
  {
    question: 'London had the first underground transport network in the world.  But when did it open?',
    choice1: 1843,
    choice2: 1853,
    choice3: 1863,
    choice4: 1873,
    answer: 3,
  },
   {
    question: 'How many tube stations does London have?',
    choice1: 200,
    choice2: 230,
    choice3: 270,
    choice4: 403,
    answer: 3,
  },
    {
    question: "On London's tube map, what colour is the Central Line?",
    choice1: 'Red',
    choice2: 'Dark Blue',
    choice3: 'Silver',
    choice4: 'Green',
    answer: 1,
  },
   {
    question: "On London's tube map, what colour is the Piccadilly Line?",
    choice1: 'Red',
    choice2: 'Dark Blue',
    choice3: 'Silver',
    choice4: 'Green',
    answer: 2,
  },
   {
    question: "On London's tube map, what colour is the Jubilee Line?",
    choice1: 'Red',
    choice2: 'Dark Blue',
    choice3: 'Silver',
    choice4: 'Green',
    answer: 3,
  },
   {
    question: "On London's tube map, what colour is the District Line?",
    choice1: 'Red',
    choice2: 'Dark Blue',
    choice3: 'Silver',
    choice4: 'Green',
    answer: 4,
  },
  {
    question: "Who designed St Paul's Cathedral?",
    choice1: 'Philip Jones',
    choice2: 'John Soanes',
    choice3: 'Edwin Luytens',
    choice4: 'Christopher Wren',
    answer: 4,
  },
  {
    question: "What bird is Trafalgar Square most famous for?",
    choice1: 'Pigeons',
    choice2: 'Ravens',
    choice3: 'Pelicans',
    choice4: 'Parrots',
    answer: 1,
  },
  {
    question: "What bird is St James's Park most famous for?",
    choice1: 'Pigeons',
    choice2: 'Ravens',
    choice3: 'Pelicans',
    choice4: 'Parrots',
    answer: 3,
  },
  {
    question: "What bird is The Tower of London most famous for?",
    choice1: 'Pigeons',
    choice2: 'Ravens',
    choice3: 'Pelicans',
    choice4: 'Parrots',
    answer: 2,
  },
  {
    question: 'Which of these is NOT a famous London Skyscraper?',
    choice1: 'The Cheesegrater',
    choice2: 'The Gherkin',
    choice3: 'The Scalpel',
    choice4: 'The Frankfurter',
    answer: 4,
  },
  {
    question:
      'Which of these is a famous landmark on the Thames?',
    choice1: 'The OXO Tower',
    choice2: 'The Bisto Tower',
    choice3: 'The PAXO Tower',
    choice4: 'The Foxtrot Tower',
    answer: 1,
  },
  {
    question: 'When was Buckingham Palace built?',
    choice1: 1603,
    choice2: 1703,
    choice3: 1803,
    choice4: 1903,
    answer: 2,
  },
   {
    question: 'How many rooms does Buckingham Palace have?',
    choice1: 445,
    choice2: 555,
    choice3: 665,
    choice4: 775,
    answer: 4,
  },
  {
    question: 'How big is Hyde Park?',
    choice1: '99 hectares',
    choice2: '121 hectares',
    choice3: '142 hectares',
    choice4: '999 hectares',
    answer: 3,
  },
  {
    question: "According to the nursery ryhme, Oranges and Lemons ring the bells of ...",
    choice1: 'St Bremans',
    choice2: 'St Bernards',
    choice3: "St Paul's",
    choice4: 'St Clements',
    answer: 4,
  },
  {
    question:
      'Which football team plays at Whiteheart Lane?',
    choice1: 'Spurs',
    choice2: 'Arsenal',
    choice3: 'West Ham',
    choice4: 'Chelsea',
    answer: 1,
  },
 {
    question:
      'Which football team plays at The Emirates?',
    choice1: 'Spurs',
    choice2: 'Arsenal',
    choice3: 'West Ham',
    choice4: 'Chelsea',
    answer: 2,
  },
   {
    question:
      'Which football team plays at the London Stadium?',
    choice1: 'Spurs',
    choice2: 'Arsenal',
    choice3: 'West Ham',
    choice4: 'Chelsea',
    answer: 3,
  },
   {
    question:
      'Which football team plays at Stamford Bridge?',
    choice1: 'Spurs',
    choice2: 'Arsenal',
    choice3: 'West Ham',
    choice4: 'Chelsea',
    answer: 4,
  },
    {
    question:
      'Which famous ship is permanently moored in The Thames?',
    choice1: 'HMS Belfast',
    choice2: 'HMS Victory',
    choice3: 'HMS Mary Rose',
    choice4: 'The Beagle',
    answer: 1,
  },
  {
    question: 'What is the population of London?',
    choice1: '7.1 million',
    choice2: '8.9 million',
    choice3: '9.7 million',
    choice4: '11.3 million',
    answer: 2,
  },
  {
    question: 'Which motorway encircles London?',
    choice1: 'The M1',
    choice2: 'The M4',
    choice3: 'The M25',
    choice4: 'The M40',
    answer: 3,
  },
]
